import {App} from '../../app.init'

App.controller('LogoutController', [
    '$scope', '$state', '$http', 'uac',
    function ($scope, $state, $http, uac) {
        $scope.logout = function () {
            uac.logout();
        }
    }
]);
