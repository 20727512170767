/**=========================================================
 * Module: constants.js
 * Define constants to inject across the application
 =========================================================*/
import {App} from '../app.init'

App
    .constant('ENV', window.__env)
    .constant('APP_COLORS', {
        'primary': '#5d9cec',
        'success': '#27c24c',
        'info': '#23b7e5',
        'warning': '#ff902b',
        'danger': '#f05050',
        'inverse': '#131e26',
        'green': '#37bc9b',
        'pink': '#f532e5',
        'purple': '#7266ba',
        'dark': '#3a3f51',
        'yellow': '#fad732',
        'gray-darker': '#232735',
        'gray-dark': '#3a3f51',
        'gray': '#dde6e9',
        'gray-light': '#e4eaec',
        'gray-lighter': '#edf1f2'
    })
    .constant('APP_MEDIAQUERY', {
        'desktopLG': 1200,
        'desktop': 992,
        'tablet': 768,
        'mobile': 480
    })

.constant('APP_REQUIRES', {})
    /*
    .constant('APP_REQUIRES', {
        // jQuery based and standalone scripts
        scripts: {
            //'modernizr': [
            //    'vendor/modernizr/modernizr.js'
            //],
            //'icons': [
            //    'vendor/fontawesome/css/font-awesome.min.css',
            //    'vendor/simple-line-icons/css/simple-line-icons.css'
            //],
            //'screenfull': [
            //    'vendor/screenfull/dist/screenfull.js'
            //],
            //'inputmask': [
            //    'vendor/jquery.inputmask/dist/jquery.inputmask.bundle.min.js'
            //],
    //'datatables': [
    //    'vendor/datatables/media/js/jquery.dataTables.min.js',
    //    'app/vendor/datatable-bootstrap/css/dataTables.bootstrap.css'
    //],
    //'datatables-pugins': [
    //    'app/vendor/datatable-bootstrap/js/dataTables.bootstrap.js',
    //    'app/vendor/datatable-bootstrap/js/dataTables.bootstrapPagination.js',
    //    'vendor/datatables-colvis/js/dataTables.colVis.js',
    //    'vendor/datatables-colvis/css/dataTables.colVis.css'
    //],
            //'parsley': [
            //    'vendor/parsleyjs/dist/parsley.min.js'
            //],
            'animo': [
                'vendor/animo.js/animo.js'
            ],
            //'loaders.css': [
            //    'vendor/loaders.css/loaders.css'
            //],
            //'filestyle': [
            //    'vendor/bootstrap-filestyle/src/bootstrap-filestyle.js'
            //],
            'moment': [
                'vendor/moment/src/moment.js'
            ]
        },
        // Angular based script (use the right module name)
        modules: [
            // { name: 'toaster', files: ['vendor/angularjs-toaster/toaster.js','vendor/angularjs-toaster/toaster.css'] }
            //{
            //    name: 'localytics.directives',
            //    files: [
            //        'vendor/chosen/chosen.jquery.min.js',
            //        'vendor/chosen/chosen.min.css',
            //        'vendor/angular-chosen-localytics/chosen.js'
            //    ]
            //},
            //{
            //    name: 'infinite-scroll',
            //    files: [
            //        'vendor/ngInfiniteScroll/build/ng-infinite-scroll.js'
            //    ]
            //},
            {
                name: 'textAngular',
                files: [
                    'vendor/textAngular/dist/textAngular.css',
                    'vendor/textAngular/dist/textAngular-rangy.min.js',
                    'vendor/textAngular/dist/textAngular-sanitize.js', // -- same as ngSanitize, might introduce problems
                    'vendor/textAngular/src/globals.js',
                    'vendor/textAngular/src/factories.js',
                    'vendor/textAngular/src/DOM.js',
                    'vendor/textAngular/src/validators.js',
                    'vendor/textAngular/src/taBind.js',
                    'vendor/textAngular/src/main.js',
                    'vendor/textAngular/dist/textAngularSetup.js'
                ],
                serie: true
            },
            //{
            //    name: 'ngWig',
            //    files: [
            //        'vendor/ng-wig/dist/ng-wig.min.js'
            //    ]
            //},
            //{
            //    name: 'angularFileUpload',
            //    files: [
            //        'vendor/angular-file-upload/dist/angular-file-upload.js'
            //    ]
            //},
            //{
            //    name: 'ngDialog',
            //    files: [
            //        'vendor/ngDialog/css/ngDialog.min.css',
            //        'vendor/ngDialog/css/ngDialog-theme-default.min.css',
            //        'vendor/ngDialog/css/ngDialog-theme-plain.min.css'
            //    ]
            //},
            //{
            //    name: 'ui.select',
            //    files: [
            //        'vendor/ui-select/dist/select.js',
            //        'vendor/ui-select/dist/select.css'
            //    ]
            //},
            {
                name: 'ui.bootstrap-slider',
                files: [
                    'vendor/seiyria-bootstrap-slider/dist/css/bootstrap-slider.min.css'
                ]
            },
            {
                name: 'ng-nestable',
                files: [
                    'vendor/angular-nestable/src/angular-nestable.js',
                    'vendor/nestable/jquery.nestable.js'
                ]
            },
            {
                name: 'html-sortable',
                files: [
                    'vendor/html5-sortable-angularjs/lib/html5-sortable.js'
                ]
            },
            //{
            //    name: 'dragularModule',
            //    files: [
            //        'vendor/dragular/dist/dragular.js',
            //        'vendor/dragular/dist/dragular.css',
            //    ]
            //},
            //{
            //    name: 'bootstrap-tour-css',
            //    files: [
            //        'vendor/bootstrap-tour/build/css/bootstrap-tour.css'
            //    ]
            //},
            {
                name: 'ui.calendar',
                files: [
                    'vendor/fullcalendar/dist/fullcalendar.css',
                    "vendor/angular-ui-calendar/src/calendar.js",
                    "vendor/fullcalendar/dist/fullcalendar.min.js",
                    //"vendor/fullcalendar/dist/gcal.js"
                ]
            }
        ]

    })
     */
;
