import {App} from '../../app.init'

App.service('event', [
    '$pusher',
    '$auth',
    'ENV',
    function ($pusher, $auth, ENV) {
        var getBearer = function () {};

        getBearer.prototype.toString = function () {
            return 'Bearer ' + $auth.getToken();
        };

        var client = new Pusher(ENV.push.key, angular.extend(ENV.push.config, {
            auth: {
                headers: {
                    'Authorization': new getBearer()
                }
            }
        }));

        var pusher = $pusher(client);
        window.pusher = pusher;

        pusher.rebind = function (from, to) {
            var binds = from.baseChannel.callbacks._callbacks;
            angular.forEach(binds, function (callbacks, name) {
                angular.forEach(callbacks, function (callback) {
                    if (name.indexOf('_pusher') == -1) {
                        to.bind(name.substr(1), callback.fn, callback.context);
                    }
                });
            });
        };

        return function () {
            return pusher;
        };
    }
]);
