/**=========================================================
 * Module: access-login.js
 * Demo for login api
 =========================================================*/
import {App} from '../../app.init'
import {UAC} from '../../app/vue/UserAccessControl'

App.controller('LoginFormController', [
    '$scope', '$http', '$state', 'uac', 'formValidate', '$timeout',
    function ($scope, $http, $state, uac, formValidate, $timeout) {

        // bind here all data from the form
        $scope.account = {};
        // place the message if something goes wrong
        $scope.authMsg = '';

        $scope.formValidate = formValidate;

        $scope.login = function () {
            $scope.authMsg = '';

            if ($scope.loginForm.$valid) {

                uac.login($scope.account.login, $scope.account.password).then(function (res) {
                    //$state.go('app.dashboard');
                });

            }
            else {
                // set as dirty if the user click directly to login so we show the validation messages
                $scope.loginForm.login.$dirty = true;
                $scope.loginForm.password.$dirty = true;
            }
        };

        let checkIfLoggedIn = function () {
            UAC
                .check()
                .then(response => {
                    if (response.data.user) {
                        $state.go('app.dashboard')
                    } else {
                        $timeout(() => {
                            checkIfLoggedIn()
                        }, 5000)
                    }
                })
                .catch(e => {
                    $timeout(() => {
                        checkIfLoggedIn()
                    }, 5000)
                })
        }

        $timeout(() => {
            checkIfLoggedIn()
        }, 1000)

    }]);
