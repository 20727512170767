/**=========================================================
 * Module: config.js
 * App routes and resources configuration
 =========================================================*/

import {App} from '../app.init'

App.config(['$stateProvider', '$locationProvider', '$urlRouterProvider',
function ($stateProvider, $locationProvider, $urlRouterProvider) {
  'use strict';

  // Set the following to true to enable the HTML5 Mode
  // You may have to set <base> tag in index and a routing configuration in your server
  $locationProvider.html5Mode(false);

  // default route
  $urlRouterProvider.otherwise('/app/dashboard');

}])
    .config(['$controllerProvider', '$compileProvider', '$filterProvider', '$provide',
    function ( $controllerProvider, $compileProvider, $filterProvider, $provide) {
      'use strict';

      // registering components after bootstrap
      App.controller = $controllerProvider.register;
      App.directive  = $compileProvider.directive;
      App.filter     = $filterProvider.register;
      App.factory    = $provide.factory;
      App.service    = $provide.service;
      App.constant   = $provide.constant;
      App.value      = $provide.value;

}]).config(['$translateProvider', function ($translateProvider) {

    let langs = window.languages
    $translateProvider.translations('pl_PL', langs.pl_PL)
    $translateProvider.translations('en_EN', langs.en_EN)

    $translateProvider.preferredLanguage('pl_PL');
    $translateProvider.fallbackLanguage('pl_PL');
    $translateProvider.useLocalStorage();
    $translateProvider.usePostCompiling(true);
    //$translateProvider.useMissingTranslationHandler('missingTranslationHandler');

}]).config(['cfpLoadingBarProvider', function(cfpLoadingBarProvider) {

    cfpLoadingBarProvider.includeBar = true;
    cfpLoadingBarProvider.includeSpinner = true;
    cfpLoadingBarProvider.latencyThreshold = 500;
    cfpLoadingBarProvider.parentSelector = 'body';
    //cfpLoadingBarProvider.parentSelector = '.wrapper > section';
}]).config(['$tooltipProvider', function ($tooltipProvider) {

    var options = {
        appendToBody: true
    };

    if(('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) {
        options['trigger'] = 'dontTrigger';
    }

    $tooltipProvider.options(options);
}]).config(['$sceDelegateProvider', function($sceDelegateProvider) {

    $sceDelegateProvider.resourceUrlWhitelist([
        'self',
        //'http://ipad.optiko.pl/video/*.mp4'
    ]);
}]);
