/**=========================================================
 * Module: main.js
 * Main Application Controller
 =========================================================*/
import {App} from '../../app.init'
import {EventBus} from '../../app/_bridge/EventBus'

App.controller('AppController',
    ['$rootScope', '$scope', '$http', '$state', '$translate', '$window', '$localStorage', '$timeout',
        'toggleStateService', 'colors', 'browser', 'cfpLoadingBar', 'uac', '$route',
        function ($rootScope, $scope, $http, $state, $translate, $window, $localStorage, $timeout,
                  toggle, colors, browser, cfpLoadingBar, uac, $route) {
            "use strict";
            $scope.uac = uac;

            $rootScope.$route = $route

            // Setup the layout mode
            $rootScope.app.layout.horizontal = true;//( $rootScope.$stateParams.layout == 'app-h');
            $rootScope.app.layout.isBoxed = true;

            // Loading bar transition
            // -----------------------------------
            var thBar;
            $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
                if ($('.wrapper > section').length) // check if bar container exists
                    thBar = $timeout(function () {
                        cfpLoadingBar.start();
                 }, 0); // sets a latency Threshold

                if (toState.external) {
                    event.preventDefault();
                    $window.open(toState.url, '_self');
                }

                EventBus.$emit('SEARCH_CLOSE')

                $rootScope.offsideOpen = false;
            });
            $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                event.targetScope.$watch("$viewContentLoaded", function () {
                    $timeout.cancel(thBar);
                    cfpLoadingBar.complete();
                });
            });

            // Hook not found
            $rootScope.$on('$stateNotFound',
                function (event, unfoundState, fromState, fromParams) {
                    console.log(unfoundState.to); // "lazy.state"
                    console.log(unfoundState.toParams); // {a:1, b:2}
                    console.log(unfoundState.options); // {inherit:false} + default options
                });
            // Hook error
            $rootScope.$on('$stateChangeError',
                function (event, toState, toParams, fromState, fromParams, error) {
                    console.log(error);
                });
            // Hook success
            $rootScope.$on('$stateChangeSuccess',
                function (event, toState, toParams, fromState, fromParams) {
                    // display new view from top
                    $window.scrollTo(0, 0);
                    // Save the route title
                    $rootScope.currTitle = $state.current.title;

                    $rootScope.previousState = fromState;

                    if (fromState.name === 'app.task.detail') {
                        $localStorage.layout.isCollapsed = false;
                    }
                });

            $rootScope.$on('changeOffsideState', function (event, state) {
                $rootScope.offsideOpen = state;
            });

            $rootScope.$on('toggleOffsideState', function (event, state) {
                $rootScope.offsideOpen = !$rootScope.offsideOpen;
            });

            $rootScope.$on('productAddedToCart', function (event, data) {
                $rootScope.$broadcast('highlightCartWithProduct', data);
            });

            $scope.toggleOffside = state => {
                $rootScope.offsideOpen = !$rootScope.offsideOpen;

                if(typeof state !== 'undefined') {
                    $state.go(state)
                }
            }

            $rootScope.currTitle = $state.current.title;

            // Close submenu when sidebar change from collapsed to normal
            $rootScope.$watch('app.layout.isCollapsed', function (newValue, oldValue) {
                if (newValue === false)
                    $rootScope.$broadcast('closeSidebarMenu');
            });

            // Restore layout settings
            if (angular.isDefined($localStorage.layout))
                $scope.app.layout = $localStorage.layout;
            else
                $localStorage.layout = $scope.app.layout;

            $rootScope.$watch("app.layout", function () {
                $localStorage.layout = $scope.app.layout;
            }, true);


            // Allows to use branding color with interpolation
            // {{ colorByName('primary') }}
            $scope.colorByName = colors.byName;

            // Restore application classes state
            toggle.restoreState($(document.body));

            // cancel click event easily
            $rootScope.cancel = function ($event) {
                $event.stopPropagation();
            };

            $rootScope.mobileMenu = function () {
                $timeout(function () {
                    $rootScope.$broadcast('mobile-menu-open')
                })
            }

            $scope.fullDiv = false;
            $scope.toggleFullDiv = function (open) {
                if (typeof open === 'undefined') {
                    open = !$scope.fullDiv
                }
                $timeout(function () {
                    $scope.fullDiv = open
                    $rootScope.app.layout.fullDiv = open
                })
            }
            $scope.sectionCss = function () {
                if (!$scope.fullDiv) return {}
                return {
                    'z-index': 130
                }
            }
            $scope.uiViewClass = function () {
                return 'eopti-full-div' + ($scope.fullDiv ? '__open' : '')
            }
            window.fs = $scope.toggleFullDiv

        }]);
