/**=========================================================
 * Module: colors.js
 * Services to retrieve global colors
 =========================================================*/
import {App} from '../../app.init'

App.factory('colors', ['APP_COLORS', function(colors) {
  
  return {
    byName: function(name) {
      return (colors[name] || '#fff');
    }
  };

}]);
